$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Music {
  margin: 4rem auto;
  padding: 2rem 1rem;
  max-width: $max-width-s;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .is-playing {
    .m-Music-play-icon--play {
      opacity: 0;
    }
    .m-Music-play-icon--pause {
      opacity: 1;
    }
  }

  &-title {
    font-family: $headline;
    font-size: 4rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  &-button {
    text-decoration: none;
    font-size: 1.2rem;
    border-radius: 40px;
    padding: 0.5rem 2rem;
    border: 2px solid white;
    display: inline-block;
    margin: 0 auto;
    color: white;
    text-align: center;
    margin: 2rem 0;
  }

  &-description {
    //text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &-player{
    height: 80vw;
    width: 80vw;
    max-width: 400px;
    max-height: 400px;
    position: relative;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  
  &-cover {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 50% 50%;
  }

  &-play-icon {
    width: 56px;
    height: 56px;
    padding: 10px;
    border: 2px solid white;
    border-radius: 50%;
    fill: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: opacity 200ms;

    &--play {
      opacity: 1;
    }

    &--pause {
      opacity: 0;
    }
  }
}