$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.is-page-store, .is-page-archiv, .is-page-store-single, .is-page-legal {
  .m-Header {
    transform: none !important;
    transition: transform 400ms;
  }
  .m-Header-link {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .m-Header-back-home, .m-Header-back-store {
    transform: none;
  }
}

.is-page-store, .is-page-archiv, .is-page-legal {
  .m-Header-back-home {
    opacity: 1;
    display: flex;
    z-index: 10;
  }
  .m-Header-back-store {
    opacity: 0;
  }
}

.is-page-store-single {
  .m-Header-back-store {
    opacity: 1;
    display: flex;
    z-index: 10;
  }
  .m-Header-back-home {
    opacity: 0;
  }
}

// .is-page-home {
//   .m-Header {
//     top: 100%;
//   }
// }

.m-Header {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 1000;
  padding: 0 1rem;
  width: 100%;
  max-width: 100%;
  overflow: visible;
  
  @include respond-to("small") {
    padding: 0 2rem;
  }

  &--home {
    border: 1px solid red;
  }
  
  &-content {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $max-width;
    margin: 0 auto;
    //border-bottom: 1px solid white;
    padding: 1rem 0;
    position: relative;
    overflow: visible;

    &:after {
      content: " ";
      display: block;
      position: absolute;
      bottom: -2px;
      top: auto;
      height: 2px;
      left: 0;
      right: 0;
      background-color: white;
    }
  }

  &-logo {
    font-family: $headline;
    color: white;
    text-decoration: none;
    font-size: 1.4rem;
    white-space: nowrap;
    
    @include respond-to("small") {
      font-size: 1.8rem;
    }

    @include respond-to("medium") {
      font-size: 2rem;
    }
  }

  &-links {
    display: flex;
    justify-content: flex-end;
    align-items: center; 
  }
  
  &-link {
    color: white;
    //border-bottom: 1px solid white;
    text-decoration: none;
    margin-left: 1rem;
    padding: 1rem 0;
    display: none;
    transition: opacity 400ms;

    svg {
      display: inline-block;
    }
    
    @include respond-to("xs") {
      display: block;

      svg {
        display: none;
      }
    }

    @include respond-to("medium") {
      margin-left: 2.5rem;
    }

    @include respond-to("large") {
      margin-left: 3.5rem;
      
      svg {
        display: inline-block;
      }
    }


    &--page {
      display: flex;
      border-bottom: none;
      background-color: white;
      color: black;
      border-radius: 40px;
      padding: 0.25rem 1rem;
      align-items: center;

      svg {
        width: 30px;
        margin-left: 10px;
        display: none;
      }

      @include respond-to("xs") {
        svg {
          display: inline-block;
        }
      }
    }
  }
  
  &-back-home, &-back-store {
    display: none;

    svg {
      display: inline-block;
      margin-right: 0;
      margin-left: 0;
      width: 90px;

      @include respond-to("xs") {
        margin-right: 10px;
      }
    }

    span {
      display: none;

      @include respond-to("xs") {
        display: inline-block;
      }
    }
  }
}