$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Archive {
  margin: 4rem auto;
  padding: 2rem 1rem;
  color: white;
  max-width: calc(#{$max-width} + 1rem);
  overflow: hidden;
  
  &-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    //border: 1px solid red;
    padding: 2rem 0;

    &:nth-child(even) {
      //border: 1px solid green;
      @include respond-to("medium") {
        padding: 2rem;
      }
    }

    img {
      transition: transform 1000ms, opacity 1000ms;
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }

    img[src=""] {
      transform: translate3d(0, 20%, 0);
      opacity: 0;
    }
  }

  &-element {
    padding: 0.5rem;
    max-width: calc(100% / 4);
    position: relative;
    cursor: pointer;
    
    @include respond-to("medium") {
      padding: 1rem;
    }

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        img {
          transition-delay: 300 + $i * 10ms;
        }
      }
    }

    &:hover {
      .m-Archive-title {
        opacity: 1;
      }
      .m-Archive-description {
        opacity: 0;
      }
      .m-Button {
        opacity: 0;
      }
    }
  }

  &-lightbox {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.8);
    transition: background-color 400ms;
    z-index: 1000;
    
    &.hidden {
      background-color: rgba(0,0,0,0);
      pointer-events: none;

      .m-Archive-lightbox-icon {
        opacity: 0;
        pointer-events: none;
      }
    }

    &-info {
      .m-Archive-title {
        margin-top: 0;
      }

      img {
        display: none;

        @include respond-to("medium") {
          display: inline-block;
        }
      }
    }

    &-image {
      position: absolute;
      pointer-events: none;
      max-width: none;
    }

    &-icon {
      position: absolute;
      left: auto;
      right: 1rem;
      top: 1rem;
      bottom: auto;
      width: 65px;
      height: 65px;
      padding: 10px;
      opacity: 1;
      transition: opacity 200ms;
      z-index: 20;
      background-color: rgba(0,0,0,0.4);
      border-radius: 50%;
      cursor: pointer;
    }
  }

  &-image {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &-info {
    display: block;
    position: absolute;
    //opacity: 0;
    transition: opacity 300ms;
    min-width: 200px;

    .m-Button {
      opacity: 0;
    }
  }

  &-title {
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    opacity: 0;
    transition: opacity 200ms;
  }

  &-description {
    margin-bottom: 1rem;
    opacity: 0;
  }
}