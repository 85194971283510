$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Intro-spacer {
  height: 100vh;
  margin-bottom: 200px;
  position: relative;
}

.m-Intro {
  background-color: #fff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  //margin-bottom: 2rem;
  margin-bottom: 120px;

  /*&:after {
    background-color: rgba(0,0,0, 0.2);
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    z-index: 15;
  }*/
  
  h1 {
    font-size: 5rem;
    line-height: 1.1;
    max-width: 1200px;
    text-align: center;
    position: relative;
    z-index: 20;


    mark {
      display: block;

      @include respond-to('medium') {
        display: inline-block;
      }
    }

    span {
      display: inline-block;
    }

    @include respond-to('medium') {
      font-size: 8vw;
    }
  }

  img {
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
    object-fit: cover;
    //transform: translate(-12%, 0);
  }

  &-video {
    z-index: 10;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: 177.77777778vh;
    min-width: 100%;
    min-height: 56.25vw;
    transform: translate(-50%, -50%);
  }

  &-border {
    position: absolute;
    display: block;
    z-index: 10;
    background-color: black;
    z-index: 30;
    
    &--top {
      left: 0;
      right: 0;
      top: 0;
      bottom: auto;
      height: 4rem;
    }

    &--right {
      left: auto;
      right: 0;
      top: 0;
      bottom: 0;
      width: 4rem;
    }

    &--bottom {
      left: 0;
      right: 0;
      top: auto;
      bottom: 0;
      height: 4rem;
    }

    &--left {
      left: 0;
      right: auto;
      top: 0;
      bottom: 0;
      width: 4rem;
    }
  }

  /*@include respond-to("medium") {
    img {
      height: auto;
      transform: none;
      width: 100%;
    }
  }*/
} 