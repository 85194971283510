$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.is-page-store-single {
  padding: 1rem;
  margin-top: 4rem;

  .m-Store-element {
    width: 100%;
    margin: 0 auto;
    max-width: $max-width;
    padding: 0;
    margin-bottom: 4rem;
    text-align: center;
  }
  
  .m-Store-title {
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: bold;
  }

  .m-Store-description {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .m-Store-image {
    max-height: 70vh;
    width: 100%;
    object-fit: contain;
  }

 /* .m-Store-info {
    background-color: white;
    width: 100%;
    margin: 1rem;
    color: black;
    padding: 1rem;
    border-radius: 40px;

    @include respond-to('medium') {
      max-width: 800px;
      width: 80%;
      margin: 0 auto;
    }
  }*/
}

.m-Store {
  color: black;
  background-color: white;
  
  &-wrapper {
    margin: 0 auto;
    padding: 2rem 1rem;
    max-width: calc(#{$max-width} + 1rem);
    overflow: hidden;
  }
  
  &-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: nowrap;
    width: 100%;
    padding: 2rem 0;

    img {
      transition: transform 1000ms, opacity 1000ms;
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }

    img[src=""] {
      transform: translate3d(0, 20%, 0);
      opacity: 0;
    }
  }

  &-description {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-price {
    margin: 0 1rem;
    font-size: 1.2rem;
  }

  &-teaser {
    color: black;
    border-top: 2px solid black;
    padding-top: 5px;
    
    .m-Store-title {
      text-decoration: none;
      font-weight: bold;
    }

    .m-Store-price {
      text-decoration: none;
      font-weight: normal;
    }
  }
  
  &-action {
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    display: flex;
    padding-top: 1rem;
  }

  &-element {
    padding: 0.5rem;
    max-width: calc(100% / 3);
    position: relative;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        img {
          transition-delay: 600 + $i * 10ms;
        }
      }
    }
    
    @include respond-to("medium") {
      padding: 1rem;
    }

    button {
      display: inline-block;
      border: none;
      margin: 0;
      text-decoration: none;
      font-family: inherit;
      font-size: 1rem;
      cursor: pointer;
      text-align: center;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: transparent;
      color: black;
    }
  }


  
  &-image {
    width: 100%;

    img {
      width: 100%;
    }
  }
}