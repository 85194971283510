$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Puzzle {
  margin: 0 auto;
  background-color: #f4efe9;
  max-width: $max-width;
  position: relative;
  margin-top: 150px;
  
  @include respond-to('small') {
    margin-top: 0;
  }

  svg {
    display: block;
  }

  &Item {
    mix-blend-mode: multiply;
    cursor: move;
    transition: filter 200ms;
    
    &:hover {
      filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.2));
    }
  }

  &Info {
    text-align: center;
    position: absolute;
    right: 0;
    color: white;
    pointer-events: none;
    bottom: calc(100% + 1rem);
    width: 100%;
    padding: 0 1rem;
    
    @include respond-to('small') {
      padding: 0;
      right: 1rem;
      text-align: right;
      width: 25%;
      bottom: 6rem;
      color: black;
    }
  }
  
  &Headline {
    font-family: $headline;
    font-size: 2rem;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &Arrow {
    position: absolute;
    opacity: 0;
    width: 7%;
    top: 27%;
    right: 21%;
    transition: opacity 500ms;
    transition-delay: 1800ms;
    z-index: 80;
    pointer-events: none;
  }

  &Outline {
    stroke-dasharray: 20;
    opacity: 0;
    stroke-width: 3;
    transition: opacity 500ms;
    transition-delay: 2000ms;
    z-index: 80;
    pointer-events: none;
  }

  &Reset {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 54px;
    height: 54px;
    background-color: black;
    padding: 10px;
    border-radius: 50%;
    z-index: 80;
    cursor: pointer;
    transition: transform 200ms;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 100%;
    }
  }
} 