$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Footer {
    background-color: black;
    color: #fff;
    text-align: center;
    width: 100%;
    
    &-container {
      padding: 2rem;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      max-width: calc(#{$max-width} + 4rem);
      flex-direction: column;
      margin: 0 auto;

      @include respond-to('medium') {
        flex-direction: row;
      }
    }


    &-legal a {
      color: white;
      text-decoration: none;
    }
  } 
  