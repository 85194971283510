$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Article {
  text-transform: none;
  margin: 2rem auto;
  padding: 2rem 1rem;
  color: white;
  align-items: center;
  flex-direction: column;
  max-width: $max-width-s;

  @include respond-to("medium") {
    flex-direction: row;
    margin: 4rem auto;
  }
  
  &--image {
    display: flex;
    max-width: calc(#{$max-width} - 200px);

    .m-Article-paragraph {
      order: 10;
    }
  }

  &-image {
    width: 100%;
    max-width: 400px;
    order: 0;
    margin: 1rem;
    overflow: hidden;
    
    @include respond-to("medium") {
      max-width: 100%;
      margin-right: 2rem;
    }

    img {
      width: 100%;
    }
    
    &--right {
      order: 20;
    }
  }

  &-paragraph {
    width: 100%;
    line-height: 1.5;
  }

  p {
    margin-bottom: 1rem;
  }

  em {
    display: block;
    padding-left: 1rem;
    padding-top: 1rem;

    @include respond-to("medium") {
      padding-left: 2rem;
    }
  }

  strong {
    font-weight: bold;
  }

  blockquote {
    font-size: 1.4rem;
    padding: 4rem 2rem;
    text-align: center;

    @include respond-to("medium") {
      font-size: 2rem;
    }
  }
}