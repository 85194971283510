$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-ImageRowTop {
  width: 100%;
  margin: 0 auto;
  position: relative;
  max-width: calc(#{$max-width} + 2rem);
  display: flex;
  //flex-direction: column;
  padding: 0.5rem;
  padding-top: 5rem;
  flex-direction: row;
  align-items: flex-start;

  @include respond-to("small") {
    padding-top: 8rem;
  }

  &-wrapper {
    width: 50%;
    margin: 0.5rem;
    display: inline-block;
    overflow: hidden;
    position: relative;

    &:after {
      content: " ";
      display: block;
      position: relative;
      //background-color: red;
      pointer-events: none;
      opacity: 0.5; 
      padding-bottom: 150%;
    }

    @include respond-to("small") {
      width: 33.3333%;
    }

  }

  &-image {
    position: absolute;
    display: block;
    width: 100%;
    transform-origin: left center;
    object-fit: cover;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}