$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Historie {
  text-transform: none;
  margin: 4rem auto;
  padding: 2rem 1rem;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: $max-width-s;
  
  &-title {
    font-family: $headline;
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  &-description {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &-entry {
    font-family: $headline;
    font-size: 1.5rem;
    line-height: 1;
    padding-right: 3rem;
  }

  &-list {
    width: 100%;
    margin-top: 2rem;
  }

  &-element {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    border-radius: 3px;
    margin-bottom: 0.5rem;
    position: relative;
    transition: background-color 200ms;
    // border-bottom: 1.5px solid white;
    // border-top: 1.5px solid white;
    
    &--expandable {
      //border: 1px solid white;
      cursor: pointer;
      //padding-right: 4.5rem;
      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }


    &.active {
      .m-Historie-expandable {
        max-height: 2000px;
      }

      .m-Historie-icon {
        transform: rotate(45deg);
      }
    }
  }

  &-image {
    max-width: 100%;
    max-height: 350px;
    height: auto;
    padding-top: 2rem;
    margin: 0 auto;
    display: block;
  }

  &-icon {
    width: 3rem;
    margin: 0 auto;
    position: absolute;
    display: block;
    right: 1rem;
    top: 0.75rem;
    //transform: translateY(-50%);
    transition: transform 300ms;
  }

  &-expandable-description {
    padding-top: 2rem;
    line-height: 1.5;
  }

  &-expandable {
    width: 100%;
    height: auto;
    max-height: 0px;
    transition: max-height 300ms;
    overflow: hidden;
  }
}