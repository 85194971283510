$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Teaser {
  width: 100%;
  color: black;
  padding: 4rem 1rem;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center center;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  
  &-wrapper {
    border: 4px solid black;
    border-radius: 30px;
    cursor: pointer;
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: block;
    z-index: 20;
    text-decoration: none;
    color: black;
    position: relative;
    display: block;
  }
  
  &-description {
    padding: 1rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: white;
    font-weight: bold;
  }

  &-content {
    width: 100%;
    min-height: 160px;
    border-bottom: 4px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include respond-to("medium") {
      min-height: 200px;
    }

    span {
      display: block;
      padding: 1rem;
      font-size: 35vw;
      font-family: $headline;
      line-height: 0.6;
      transform: translateY(-15%);
      pointer-events: none;
      width: 100%;
      text-align: center;

      @include respond-to("medium") {
        font-size: 40vw;
      }

      @include respond-to("large") {
        font-size: 500px;
      }
    }
  }

  &-headline {
    display: inline-block;
    position: relative;
    font-size: 6vw;
    display: flex;
    flex-direction: column;
    //width: 100%;
    margin: 4rem 0;

    @include respond-to("medium") {
      margin: 8rem 0;
      font-size: 4rem;
    }
  }

  &-line {
    font-family: $headline;
    font-weight: bold;
    text-align: center;
    padding: 0 1rem;

    &--01 {
      border-bottom: 4px solid black;
    }
  }

  &-button, &-description-element {
    flex-shrink: 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  &-description-element {
    display: none;

    @include respond-to('xs') {
      display: flex;
    }
  }

  &-arrow {
    position: relative;
    display: inline-block;
    width: 100%;

    &:after {
      border: 2px solid black;
      position: absolute;
      content: " ";
      top: calc(50% - 2px);
      left: 20px;
      right: 20px;
    }

    svg {
      position: absolute;
      display: block;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 48px;
      height: 48px;
    }
  }
} 