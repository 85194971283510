$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
@import './../node_modules/reset-css/sass/reset';

@import url('https://fonts.googleapis.com/css2?family=Caudex:wght@400;700&family=Montserrat:wght@400;700&display=swap');


* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  margin-top: 0 !important;
  font-size: 14px;

  &::-webkit-scrollbar {
    display: none;
  }
 
  @include respond-to("small") {
    font-size: 16px;
  }
}

body {
  -ms-overflow-style: none;
  font-weight: normal;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  line-height: 1.4;
  background-color: black;
  color: white;
  //width: 100%;
  //overflow: hidden;

  /*&[data-context="site"] {
    > [data-context="page"] {
      opacity: 1;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
    }
  }*/
}

.lockScroll {
  height: 100%;
  overflow: hidden;
}

h1, blockquote {
  font-family: $headline;
}

.m-Content--404 {
  min-height: 100vh;
  padding-top: 8rem;
  text-align: center;

  h1 {
    font-size: 8rem;
    font-family: $headline;
    font-weight: bold;
  }

  p {
    margin-bottom: 1rem;
  }
}

mark {
  background-color: transparent;
  color: inherit;
}

.debug {
  display: none;
  position: fixed;
  font-size: 12px;
  top: 1rem;
  left: 1rem;
  color: white;
  background-color: red;
  border-radius: 40px;
  padding: 10px 15px;
  z-index: 9999999999;
}

* {
  &::selection {
    background: white;
    color: black;
  }
}


.vimeo {
  margin: 0 auto;
  padding: 0 0 6rem 0;
  max-width: 800px;
  
  &--top {
    padding: 2rem 0 2rem 0;
  }

  &_wrapper {
    width: 100%;
    position: relative;
    display: block;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
}