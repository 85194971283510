$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Button {
  text-decoration: none;
  border-radius: 40px;
  padding: 0.5rem 1rem;
  background-color: white;
  color: black !important;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  width: auto;
  
  &--black {
    background-color: black;
    color: white !important;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
} 