$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Legal {
  text-transform: none;
  margin: 4rem auto;
  padding: 2rem 1rem;
  color: white;
  align-items: center;
  flex-direction: column;
  max-width: $max-width-s;

  @include respond-to("medium") {
    flex-direction: row;
  }

  h1 {
    padding: 0 1rem;
    margin-bottom: 0;
    font-weight: bold;
    font-family: $headline;
    font-size: 3rem;
  }

  .m-Article {
    margin-top: 1rem;
  }

  a {
    font-weight: bold;
    color: white;
  }
  
  h2, h3, h4, h5 {
    font-weight: bold;
    font-family: $headline;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1rem;
    margin: 1rem 0;
  }

  &-text {
    margin-bottom: 1rem;
    line-height: 1.5;
  }
}