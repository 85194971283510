$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-ImageRow {
  width: 100%;
  margin: 0 auto;
  position: relative;
  max-width: calc(#{$max-width} + 2rem);
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  
  @include respond-to("small") {
    flex-direction: row;
    align-items: center;
  }

  &-wrapper {
    width: 50%;
    margin: 0.5rem;
    display: inline-block;
    overflow: hidden;

    &:nth-child(1) {
      align-self: flex-start;
    }
    &:nth-child(2) {
      align-self: center;
    }
    &:nth-child(3) {
      align-self: flex-end;
    }
    
    @include respond-to("small") {
      width: 33.3333%;
    }

  }

  &-image {
    display: block;
    width: 100%;
    transform-origin: left center;
  }
}