$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-Contact {
  width: 100%;
  margin: 0 auto;
  position: relative;
  max-width: $max-width;
  padding: 0.5rem;
  text-decoration: none;
  font-size: 1.5rem;
  font-family: $headline;
  text-align: center;
  color: white;
  padding: 8rem 1rem;

  a {
    text-decoration: none;
    color: white;
  }
  
  @include respond-to("small") {
    font-size: 3rem;
  }

  @include respond-to("medium") {
    font-size: 4rem;
  }
}