$max-width-s: 800px;
$max-width: 1200px;
$max-width-xl: 1600px;

$headline: 'Caudex', serif;

$breakpoints: (
  xxs: 360px,
  xs: 620px,
  small: 750px,
  medium: 900px,
  large: 1280px,
);

@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
.m-SubpageIntro {
  width: 100%;

  &-wrapper {
    display: block;
    max-width: calc(#{$max-width} + 4rem);
    margin: 0 auto;
    padding: 8rem 2rem 4rem 2rem;
    
    @include respond-to("medium") {
      padding: 4rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-title {
    font-family: $headline;
    font-size: 3rem;
    width: 100%;
    display: inline-block;
    padding-right: 2rem;
    line-height: 1;
    margin-bottom: 1rem;
    
    @include respond-to("medium") {
      margin-bottom: 0;
      max-width: 420px;
      font-size: 4rem;
    }
  }
  
  &-text {
    line-height: 1.5;
    width: 100%;
    max-width: 600px;
    margin: 0;
    padding-left: 0;
    padding-right: 0;

    em {
      padding-left: 0;
    }
  }
  
  &-image {
    height: 30vh;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 4rem;
    background-position: center center;
    
    @include respond-to("small") {
      height: 50vh;
    }
  }
} 